/* Image Carousel, Single Image */

$(".js-imageCarouselSingle").each((index, element) => {
  $(element).slick({
    dots: true,
    arrows: true,
    appendDots: $(".imageCarousel__dots"),
    prevArrow: $(".imageCarousel__arrow.-prev"),
    nextArrow: $(".imageCarousel__arrow.-next"),
    responsive: [
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
    ]
  });
});

const imageGallerySliders = document.querySelectorAll(".js-imageGallerySlider");

imageGallerySliders.forEach((sliderContainer, index) => {

    // Selectors for each part of the slide that needs a unique selector created
    const slider = sliderContainer.querySelector('.imageGallerySlider');
    const sliderDots = sliderContainer.querySelector('.imageGallerySlider__dots');
    const sliderPrevArrow = sliderContainer.querySelector('.imageGallerySlider__arrow.-prev');
    const sliderNextArrow = sliderContainer.querySelector('.imageGallerySlider__arrow.-next');

    // Create unique slider, dots, and prev/next arrows for each slider on the page
    slider.classList.add(`imageGallerySlider--${index}`);
    sliderDots.classList.add(`imageGallerySlider__dots--${index}`);
    sliderPrevArrow.classList.add(`imageGallerySlider__arrow.-prev--${index}`);
    sliderNextArrow.classList.add(`imageGallerySlider__arrow.-next--${index}`);

    // Unique Selector for each slider
    const sliderParent = document.querySelector(`.imageGallerySlider--${index}`);

    // Creation Slider
    $(sliderParent).slick({
        centerMode: true,
        centerPadding: '0px',
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: true,
        arrows: true,
        variableWidth: false,
        appendDots: $(sliderDots),
        prevArrow: $(sliderPrevArrow),
        nextArrow: $(sliderNextArrow),
        responsive: [
        {
            breakpoint: 950,
            settings: {
                slidesToShow: 1,
                centerMode: true,
                centerPadding: '40px',
                slidesToShow: 1,
            }
        }
        ]
    });

});

/* Full Width Slider */

$(".js-fullWidthCarousel").each((index, element) => {
  $(element).slick({
    dots: true,
    arrows: true,
    appendDots: $(element)
      .parents(".fullWidthCarousel")
      .find(".fullWidthCarousel__dots"),
    prevArrow: $(element)
      .parents(".fullWidthCarousel")
      .find(".fullWidthCarousel__arrow.-prev"),
    nextArrow: $(element)
      .parents(".fullWidthCarousel")
      .find(".fullWidthCarousel__arrow.-next")
  });
});

/* News Slider (found in heros) */

$(".js-newsSlider").each((index, element) => {
  $(element).slick({
    dots: true,
    arrows: true,
    appendDots: $(element)
      .parents(".newsSlider")
      .find(".newsSlider__dots"),
    prevArrow: $(element)
      .parents(".newsSlider")
      .find(".newsSlider__arrow.-prev"),
    nextArrow: $(element)
      .parents(".newsSlider")
      .find(".newsSlider__arrow.-next")
  });
});

/* Image Card Slider - found in individual events pages */
/* Also found in resources, but code for that is edited in resources.js */

$(".js-imageCardSlider").each((index, element) => {
  $(element).slick({
    arrows: true,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    draggable: false,
    prevArrow: $(element)
      .parents(".imageCardSlider")
      .find(".imageCardSlider__arrow.-prev"),
    nextArrow: $(element)
      .parents(".imageCardSlider")
      .find(".imageCardSlider__arrow.-next"),
    responsive: [
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
      {
          breakpoint: 950,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          }
      }
    ]
  });
});

